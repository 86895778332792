import {
  ACTION_TYPE,
  LOAD_STATUS,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  CAR_HIRE_SEO_EVENT_COMPONENT,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import { setCarHireSeoEventValue } from '../../sessionStorage';

export const logMiniEventForClick = (
  linkUrl?: string,
  value?: string | string[] | number | null,
) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.INFO_SNIPPET,
    component_action: COMPONENT_ACTION.INFO_SNIPPET.INFO_SNIPPET_CLICKED,
    link_info: {
      url: linkUrl,
      content: value,
    },
  });
  // Write source_page.source_component to session storage after component was clicked.
  // This source_component is used on dayView page to log a Mini-Event,
  // that help analyzing the enrichment of Landing page components.
  // https://confluence.skyscannertools.net/display/CHS/Conversion+Rate+%3A+From+Landing+Page+To+Search+Result+Page
  setCarHireSeoEventValue(
    'source_component',
    CAR_HIRE_SEO_EVENT_COMPONENT.INFO_SNIPPET,
    'source_page',
  );
};

export const logMinEventForLoading = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_LOADED,
    component_name: COMPONENT_NAME.INFO_SNIPPET,
    load_status: LOAD_STATUS.LOADED,
  });
};
