import type { Component } from 'react';
import { Fragment, useEffect, useState } from 'react';

import { GLOBAL } from 'saddlebag-browser';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import { withAlignment } from '@skyscanner/backpack-web/bpk-component-icon';
import AirlineSingleIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airline';
import AirlineIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airline--multiple';
import AirportsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/airports';
import CalendarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/calendar';
import Cars from '@skyscanner/backpack-web/bpk-component-icon/lg/cars';
import CityIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city';
import CityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import DepartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/depart';
import DurationIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/duration';
import FlightIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/flight';
import HeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';
import HotelsIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels';
import HotelsPetsAllowedIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/hotels--pets-allowed';
import Insurance from '@skyscanner/backpack-web/bpk-component-icon/lg/insurance';
import Location from '@skyscanner/backpack-web/bpk-component-icon/lg/location';
import MoneyIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/money';
import PriceTagIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/price-tag';
import SocialLikeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/social-like';
import StarIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/star';
import ThumbsUpIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/thumbs-up';
import TimeIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/time';
import TrendIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/trend';
import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import {
  BpkTableRow,
  BpkTableCell,
  BpkTableHeadCell,
} from '@skyscanner/backpack-web/bpk-component-table';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';
import { iconSizeLg, lineHeightBase } from 'bpk-tokens/tokens/base.es6';

import { anchorScroll } from '../../utils';

import { logMiniEventForClick, logMinEventForLoading } from './miniEvent';

import type { SnippetProps } from 'common-types/types/InfoSnippetProps';

import STYLES from './Snippet.module.scss';

const iconsMap: { [key: string]: typeof Component } = {
  flight: withAlignment(FlightIcon, lineHeightBase, iconSizeLg),
  trend: withAlignment(TrendIcon, lineHeightBase, iconSizeLg),
  money: withAlignment(MoneyIcon, lineHeightBase, iconSizeLg),
  time: withAlignment(TimeIcon, lineHeightBase, iconSizeLg),
  calendar: withAlignment(CalendarIcon, lineHeightBase, iconSizeLg),
  airline: withAlignment(AirlineIcon, lineHeightBase, iconSizeLg),
  airlineSingle: withAlignment(AirlineSingleIcon, lineHeightBase, iconSizeLg),
  duration: withAlignment(DurationIcon, lineHeightBase, iconSizeLg),
  city: withAlignment(CityIcon, lineHeightBase, iconSizeLg),
  cityCenter: withAlignment(CityCenterIcon, lineHeightBase, iconSizeLg),
  priceTag: withAlignment(PriceTagIcon, lineHeightBase, iconSizeLg),
  depart: withAlignment(DepartIcon, lineHeightBase, iconSizeLg),
  airports: withAlignment(AirportsIcon, lineHeightBase, iconSizeLg),
  heart: withAlignment(HeartIcon, lineHeightBase, iconSizeLg),
  socialLike: withAlignment(SocialLikeIcon, lineHeightBase, iconSizeLg),
  thumbsUp: withAlignment(ThumbsUpIcon, lineHeightBase, iconSizeLg),
  star: withAlignment(StarIcon, lineHeightBase, iconSizeLg),
  hotelsPetsAllowed: withAlignment(
    HotelsPetsAllowedIcon,
    lineHeightBase,
    iconSizeLg,
  ),
  hotels: withAlignment(HotelsIcon, lineHeightBase, iconSizeLg),
  insurance: withAlignment(Insurance, lineHeightBase, iconSizeLg),
  cars: withAlignment(Cars, lineHeightBase, iconSizeLg),
  location: withAlignment(Location, lineHeightBase, iconSizeLg),
};

const EmptyIcon = () => null;
const CONTENT_TYPE = {
  BUTTON: 'button',
  LINK: 'link',
  LIST: 'list',
  TEXT: 'text',
};
const $document = GLOBAL.getDocument();

const Snippet = ({
  ariaText,
  icon,
  id,
  isMobile = false,
  label,
  linkData,
  linkUrl,
  scrollId,
  titleData = {},
  value,
}: SnippetProps) => {
  const Icon = iconsMap[icon] || EmptyIcon;

  const [contentType, setContentType] = useState(
    // eslint-disable-next-line no-nested-ternary
    linkData
      ? CONTENT_TYPE.LIST
      : linkUrl
      ? CONTENT_TYPE.LINK
      : CONTENT_TYPE.TEXT,
  );

  let valueNode;
  if (Array.isArray(value)) {
    // If the `value` prop is an array, we want to show each string element on a new line
    // We do this by adding a <li> as a list
    const listNode = (value as string[]).flatMap(
      (line: string | number, index: number) => (
        <li key={`${line}-li`}>{line}</li>
      ),
    );
    valueNode = <ul className="value">{listNode}</ul>;
  }
  const handleClick = (
    url?: string,
    val?: string | string[] | number | null,
  ) => {
    logMiniEventForClick(url, val);
  };

  const onScrollClick = () => {
    logMiniEventForClick(scrollId, value);
    anchorScroll(scrollId);
  };

  useEffect(() => {
    if (scrollId && !!$document.getElementById(scrollId)) {
      setContentType(CONTENT_TYPE.BUTTON);
    }
  }, [scrollId]);

  logMinEventForLoading();

  let cellContent;
  if (contentType === CONTENT_TYPE.LIST) {
    cellContent = linkData?.map((linkItem: any, index: number) => (
      <Fragment key={linkItem.airportName}>
        {index > 0 && ', '}
        {linkItem.link ? (
          <BpkLink
            data-testid="linkurl"
            href={linkItem.link}
            aria-label={ariaText}
            onClick={() => handleClick(linkItem.link, linkItem.airportName)}
            className={STYLES.Snippet__clickAndValueLink}
            {...titleData}
          >
            {linkItem.airportName}
          </BpkLink>
        ) : (
          <BpkText
            data-testid="subtext"
            textStyle={isMobile ? TEXT_STYLES.heading5 : TEXT_STYLES.heading4}
            aria-label={ariaText}
            className={STYLES.Snippet__clickAndValue}
            {...titleData}
          >
            {linkItem.airportName}
          </BpkText>
        )}
      </Fragment>
    ));
  } else if (contentType === CONTENT_TYPE.BUTTON) {
    cellContent = (
      <BpkButton
        link
        aria-label={ariaText}
        className={STYLES.Snippet__clickAndValue}
        onClick={() => onScrollClick()}
      >
        <span className={STYLES.Snippet__valueText}>{valueNode || value}</span>
      </BpkButton>
    );
  } else if (contentType === CONTENT_TYPE.LINK) {
    cellContent = (
      <BpkLink
        data-testid="linkurl"
        href={linkUrl}
        rel="nofollow"
        aria-label={ariaText}
        onClick={() => handleClick(linkUrl, value)}
        className={STYLES.Snippet__clickAndValueLink}
        {...titleData}
      >
        {valueNode || value}
      </BpkLink>
    );
  } else if (contentType === CONTENT_TYPE.TEXT) {
    cellContent = (
      <BpkText
        data-testid="subtext"
        textStyle={isMobile ? TEXT_STYLES.heading5 : TEXT_STYLES.heading4}
        aria-label={ariaText}
        className={STYLES.Snippet__clickAndValue}
        {...titleData}
      >
        {valueNode || value}
      </BpkText>
    );
  }

  return (
    <BpkTableRow className={STYLES.Snippet} data-testid={id}>
      <BpkTableHeadCell className={STYLES.Snippet__row}>
        <Icon className={STYLES.Snippet__icon} />
        <BpkText
          data-testid="snippet-header"
          className={STYLES.label}
          textStyle={TEXT_STYLES.bodyDefault}
        >
          {label}
        </BpkText>
      </BpkTableHeadCell>
      <BpkTableCell className={STYLES.Snippet__row}>{cellContent}</BpkTableCell>
    </BpkTableRow>
  );
};

export default Snippet;
